import React, { useEffect, useMemo, useState } from "react";
import * as Sentry from "@sentry/react";
import { Link, Redirect, useParams } from "react-router-dom";

import Loader from "../components/Loader";
import AppRoutes from "../constants/rotues";
import { isAndroid } from "../utils/device";
import PoweredBy from "../components/PoweredBy";
import {
  getReviewData,
  ReviewData,
  createLinkTrackingLog,
} from "../services/api";

type Params = { conversationId: string };

interface Props {
  setBelowExpectations: (value: boolean) => any;
}

const GetSentiment = ({ setBelowExpectations }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isErrored, setIsErrored] = useState(false);
  const [reviewData, setReviewData] = useState<ReviewData | null>(null);

  const { conversationId } = useParams<Params>();
  const showGoogleReviewsLink = useMemo(() => isAndroid(), []);

  const removeQueryParams = () => {
    try {
      window.history.replaceState({}, "", window.location.pathname);
    } catch (error) {
      console.log(error);
    }
  };

  const trackLink = async (extra: any) => {
    try {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      const sessionId: any = urlParams.get("sessionId");
      const shortenId: any = urlParams.get("shortenId");

      if (!shortenId || !sessionId)
        throw new Error("shortenId and sessionId are required!");
      await createLinkTrackingLog(shortenId, sessionId, extra);
    } catch (error) {
      console.log(error);
      return { success: false };
    }
  };

  const getReviewDataAndTrackLink = async (conversationId: string) => {
    try {
      // tracking before data is fetched
      let metaDataBeforeFetchingData = {
        "reviews.emitrr.com": { beforeFetchingData: true },
      };
      await trackLink(metaDataBeforeFetchingData);

      // fetching review data from conversation
      const data = await getReviewData(conversationId);
      setIsLoading(false);
      setReviewData(data);

      // tracking after data is fetched
      let metaDataAfterFetchingData = {
        "reviews.emitrr.com": {
          afterFetchingData: true,
          ...(data && { data }),
        },
      };
      await trackLink(metaDataAfterFetchingData);
      removeQueryParams();
    } catch (error) {
      Sentry.captureMessage(navigator.userAgent);
      Sentry.captureException(error);
      console.error(error);
      setIsErrored(true);
    }
  };

  useEffect(() => {
    getReviewDataAndTrackLink(conversationId);
  }, [conversationId]);

  useEffect(() => {
    setBelowExpectations(false);
  }, []);

  if (isErrored) {
    return (
      <Redirect
        to={{
          pathname: AppRoutes.ERROR_PAGE,
          state: {
            message:
              "Unable to get conversation data. Please try after sometime.",
          },
        }}
      />
    );
  }

  if (isLoading || !reviewData) {
    return (
      <div className="page page--center">
        <Loader />
      </div>
    );
  }

  const review = reviewData.payload.review;

  return (
    <div className="page page--hcenter">
      <img className="page__logo" src={review.logo} alt="" />
      <div className="page__message">{review.greetingText}</div>
      {showGoogleReviewsLink && review?.googleReviewLink ? (
        <a
          className="page__action page__action--google"
          href={review.googleReviewLink}
        >
          Great!
        </a>
      ) : (
        <Link
          className="page__action page__action--blue"
          to={{
            pathname: AppRoutes.SELECT_REVIEW_SITE(conversationId),
            state: review,
          }}
        >
          Great!
        </Link>
      )}
      <Link
        className="page__action"
        to={{
          pathname: AppRoutes.GET_REVIEW(conversationId),
          state: review,
        }}
      >
        Below Expectations
      </Link>
      <PoweredBy show={review.emitrrBranding} />
    </div>
  );
};

export default GetSentiment;
