import React, { useMemo } from "react";
import { useHistory, useParams, Redirect } from "react-router-dom";

import { Review } from "../services/api";
import AppRoutes from "../constants/rotues";
import PoweredBy from "../components/PoweredBy";
import { isiOS } from "../utils/device";

type Params = { conversationId: string };

interface Props {
  isBelowExpectations: boolean;
}

const SelectReviewSite = ({ isBelowExpectations }: Props) => {
  const { location } = useHistory<Review>();
  const { conversationId } = useParams<Params>();
  const review = location.state;
  const checkiOSDevice = useMemo(() => isiOS(), []);
  if (!review) {
    return <Redirect to={AppRoutes.GET_SENTIMENT(conversationId)} />;
  }
  let faceBookReviewLink;
  if (checkiOSDevice && review.facebookReviewLink && review.facebookReviewLink !== "" && (review.facebookReviewLink).includes("reviews")) {
    faceBookReviewLink = (review.facebookReviewLink).split("reviews")[0];
  }else {
    faceBookReviewLink = review.facebookReviewLink;
  }
  return (
    <div className="page page--hcenter">
      <img className="page__logo" src={review.logo} alt="" />
      <p className="page__message">
        {isBelowExpectations
          ? review.negativeFeedbackText
          : review.positiveFeedbackText}
      </p>
      {review.googleReviewLink && review.googleReviewLink !== "" ? (
        <a
          className="page__action page__action--google"
          href={review.googleReviewLink}
        >
          Google
        </a>
      ) : null}
      {faceBookReviewLink && faceBookReviewLink !== "" ? (
        <a
          className="page__action page__action--fb"
          href={faceBookReviewLink}
        >
          Facebook
        </a>
      ) : null}
      {review.yelpReviewLink && review.yelpReviewLink !== "" ? (
        <a
          className="page__action page__action--yelp"
          href={review.yelpReviewLink}
        >
          Yelp
        </a>
      ) : null}
      {review.bbbReviewLink && review.bbbReviewLink !== "" ? (
        <a
          className="page__action page__action--bbb"
          href={review.bbbReviewLink}
        >
          BBB
        </a>
      ) : null}
      <PoweredBy show={review.emitrrBranding} />
    </div>
  );
};

export default SelectReviewSite;
