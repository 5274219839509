import "whatwg-fetch";

const BASE_URL = process.env.REACT_APP_API;

export async function request<T = unknown>(
  input: RequestInfo,
  init?: RequestInit | undefined
) {
  const response = await window.fetch(input, init);

  if (response.status >= 500) {
    throw await response.json();
  }

  return (await response.json()) as T;
}

export interface Review {
  logo: string;
  greetingText: string;
  emitrrBranding: boolean;
  bbbReviewLink: string;
  yelpReviewLink: string;
  googleReviewLink: string;
  facebookReviewLink: string;
  negativeFeedbackText: string;
  positiveFeedbackText: string;
}

export interface ReviewData {
  code: number;
  payload: {
    review: Review;
  };
}

export function getReviewData(conversationId: string) {
  return request<ReviewData>(
    `${BASE_URL}/teambot/get-review-data/${conversationId}`
  );
}

export function submitFeedback(conversationId: string, message: string) {
  const body = JSON.stringify({ message });
  return request(`${BASE_URL}/teambot/negative-feedback/${conversationId}`, {
    headers: { "Content-Type": "application/json" },
    method: "POST",
    body,
  });
}

export function createLinkTrackingLog(
  shortenId: any,
  sessionId: any,
  extra: any
) {
  const body = JSON.stringify({
    shortenId,
    sessionId,
    ...(extra && { extra }),
  });
  return request(`${BASE_URL}/link-tracking/create-log`, {
    headers: { "Content-Type": "application/json" },
    method: "POST",
    body,
  });
}
